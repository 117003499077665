import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  Button
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { post } from '../../util/api';
import { 
  sendEnquiry, 
  fetchTransactionLineItems, 
  setInitialValues, 
  subscribe,
  subscribePaypal,
  requestSubscribe } from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      date: null,
      loading: false,
      subscriptionPeriodLimit: null,
      stripeConnected: false,
      paypalConnected: false,
      pageLoading: false
    };
    

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this);
    this.onSubmitRequestSubscribe = this.onSubmitRequestSubscribe.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setSubscriptionPeriodLimit = this.setSubscriptionPeriodLimit.bind(this);
    this.onSendToPaypalSubscribe = this.onSendToPaypalSubscribe.bind(this);
  }

  componentDidMount() {
     const listingId = this.props.params.id;
     const urlParams = new URLSearchParams(window.location.search);
     const paypalSubscriptionWaiting = urlParams.get('paypal');
      if(paypalSubscriptionWaiting){
        this.setState({
          pageLoading: true
        })
     }

    return post('/api/check-stripe', { listingId }).then(resp => {
      const stripeConnected = resp.stripe;

      if(stripeConnected){
        this.setState({
          stripeConnected: true
       })
      }
    
      // const authorId = resp.authorId;
      //    return post('/api/check-paypal-status', {userId: authorId}).then(res => {
      //         if(res !== 'not connected'){
      //            this.setState({
      //               paypalConnected: true
      //            })
      //         }

      //    }).then(resp => {

         
      //     const type = urlParams.get('type');


      //     if(paypalSubscriptionWaiting && type === 'instant'){


      //         const urlParams = new URLSearchParams(window.location.search);
      //         const selectedDate = urlParams.get('selectedDate');
      //         const subscriptionPeriodLimit = parseInt(urlParams.get('subscriptionPeriodLimit'));

      //         const { history, params, onSendEnquiry } = this.props;
      //         const routes = routeConfiguration();
      //         const listingId = new UUID(params.id);
      //         this.setState({ loading: true });
      //         const rawParams = this.props.params;
      //         const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
      //         const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
      //         const currentListing =
      //           isPendingApprovalVariant || isDraftVariant
      //             ? ensureOwnListing(getOwnListing(listingId))
      //             : ensureListing(this.props.getListing(listingId));
      //         const currentUser = this.props.currentUser;
          
      //         //email info
      //         const formatedDate = this.state.date?.format("Do MMMM YYYY");
      //         const monthlyDateOfSubsPayment = this.state.date?.format("Do");
      //         const minRentingMonths = currentListing?.attributes.publicData.minRentingMonths;
      //         const price = currentListing.attributes.price;
      //         const intl = this.props.intl;
      //         const { priceTitle } = priceData(price, intl);
      //         const emailInfo = {
      //           location: currentListing?.attributes.publicData.location.address,
      //           owner: currentListing?.author.attributes.profile.displayName,
      //           tenant: currentUser.attributes.profile.displayName,
      //           rentPrice: priceTitle,
      //           rentStart: formatedDate,
      //           rentDuration: `Minimum ${minRentingMonths} ${minRentingMonths.length === 1 ? 'month' : 'months'}`,
      //           commission: priceTitle,
      //           monthlyDateOfSubsPayment: monthlyDateOfSubsPayment
      //         }
          
      //        return this.props.onSubscribePaypal(listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo)
      //           .then(txId => {
      //             //this.setState({ enquiryModalOpen: false });
          
      //             // Redirect to OrderDetailsPage
      //             history.push(
      //               createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
      //             );
      //           })
      //           .catch(() => {
      //             // Ignore, error handling in duck file
      //           });
            


      //     }else{
      //       this.setState({
      //         pageLoading: false
      //       })
      //     }


    //      }).catch(e => console.log(e))
     }).catch(e => console.log(e))
  }

  setSubscriptionPeriodLimit(selectedOption) {
    this.setState({ subscriptionPeriodLimit: { ...selectedOption } });
  }

  setDate(date) {
    this.setState({ date: date });
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  onSubmitSubscribe() {
    const { history, params, onSubscribe } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const selectedDate= this.state.date;
    this.setState({ loading: true });
    const rawParams = this.props.params;
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(this.props.getListing(listingId));
    const currentUser = this.props.currentUser;
    const subscriptionPeriodLimit = this.state.subscriptionPeriodLimit?.value;

    //email info
    const formatedDate = this.state.date?.format("Do MMMM YYYY");
    const monthlyDateOfSubsPayment = this.state.date?.format("Do");
    const minRentingMonths = currentListing?.attributes.publicData.minRentingMonths;
    const price = currentListing.attributes.price;
    const intl = this.props.intl;
    const { priceTitle } = priceData(price, intl);
    const emailInfo = {
      location: currentListing?.attributes.publicData.location.address,
      owner: currentListing?.author.attributes.profile.displayName,
      tenant: currentUser.attributes.profile.displayName,
      rentPrice: priceTitle,
      rentStart: formatedDate,
      rentDuration: `Minimum ${minRentingMonths} ${minRentingMonths.length === 1 ? 'month' : 'months'}`,
      commission: priceTitle,
      monthlyDateOfSubsPayment: monthlyDateOfSubsPayment
    }

    const authorId = currentListing.author.id.uuid;
    return onSubscribe(listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo, authorId)
      .then(txId => {
        //this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
       return history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  onSubmitRequestSubscribe(){
  //requestSubscribe
  const { history, params, onRequestSubscribe } = this.props;
  const routes = routeConfiguration();
  const listingId = new UUID(params.id);
  const selectedDate= this.state.date;
  this.setState({ loading: true });
  const rawParams = this.props.params;
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(this.props.getListing(listingId));
  const currentUser = this.props.currentUser;
  const subscriptionPeriodLimit = this.state.subscriptionPeriodLimit?.value;


  //email info
  const formatedDate = this.state.date?.format("Do MMMM YYYY");
  const monthlyDateOfSubsPayment = this.state.date?.format("Do");
  const minRentingMonths = currentListing?.attributes.publicData.minRentingMonths;
  const price = currentListing.attributes.price;
  const intl = this.props.intl;
  const { priceTitle } = priceData(price, intl);
  const emailInfo = {
    location: currentListing?.attributes.publicData.location.address,
    owner: currentListing?.author.attributes.profile.displayName,
    tenant: currentUser.attributes.profile.displayName,
    rentPrice: priceTitle,
    rentStart: formatedDate,
    rentDuration: `Minimum ${minRentingMonths} ${minRentingMonths.length === 1 ? 'month' : 'months'}`,
    commission: priceTitle,
    monthlyDateOfSubsPayment: monthlyDateOfSubsPayment
  }


  onRequestSubscribe(listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo)
    .then(txId => {
      //this.setState({ enquiryModalOpen: false });

      // Redirect to OrderDetailsPage
      history.push(
        createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
      );
    })
    .catch(() => {
      // Ignore, error handling in duck file
    });
  }

  onSendToPaypalSubscribe(){
    //props for return url
    const selectedDate = this.state.date.toISOString();
    const subscriptionPeriodLimit = this.state.subscriptionPeriodLimit?.value.toString();
    const { history, params, onSubscribe } = this.props;
    const routes = routeConfiguration();
 
    const listingId = new UUID(params.id);
    this.setState({ loading: true });
    const rawParams = this.props.params;
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(this.props.getListing(listingId));
    const currentUser = this.props.currentUser;

    const hostId = currentListing.author.id.uuid;
    const customerId = currentUser.id.uuid;
    const planId = currentListing.attributes.publicData.paypal.subscriptionId;
    const givenName = currentUser.attributes.profile.firstName;
    const surname = currentUser.attributes.profile.lastName;
    const customerEmailAdress = currentUser.attributes.email;
    const subscriptionPrice = currentListing.attributes.price.amount / 100;
    const isLocalHost = typeof window !== 'undefined' ?  window.location.hostname === 'localhost' : false;
    const returnUrl = typeof window !== 'undefined' ? `${isLocalHost ? 'http://garagio-dev.herokuapp.com/' : window.location.href}?paypal=true&selectedDate=${selectedDate}&subscriptionPeriodLimit=${subscriptionPeriodLimit}&type=instant` : '';

    const body = {
      hostId,
      customerId,
      planId,
      givenName,
      surname,
      subscriptionPrice,
      returnUrl,
      subscriptionPeriodLimit,
      selectedDate,
      customerEmailAdress
    }

    return post('/api/paypal-subscribe', body).then(resp => {
      if(typeof window !== 'undefined'){
        window.location.href = resp;
      }

    }).catch(e => console.log(e))


  }

  

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      sendSubscribeInProgress,
      sendSubscribeError,
      sendRequestSubscribeInProgress,
      sendRequestSubscribeError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
    } = this.props;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };
    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';
    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    const isSubscribed = currentListing?.attributes.publicData?.subscriptionObj?.subscribed;
    const endingAt = currentListing?.attributes.publicData?.subscriptionObj?.endingAt;
    const today = new Date().getTime();
    const endingTime = endingAt ? new Date(endingAt * 1000).getTime() : false; //*1000 because we need miliseconds, not seconds
    const isLastSubscriptionEnded = endingAt ? endingTime < today : false;  
    
    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const isInstant = currentListing.attributes.publicData.bookingType === 'instant';

    const bookingTitle = (
      <FormattedMessage id={isInstant ? "ListingPage.bookingTitleInstant" : "ListingPage.bookingTitle"} values={{ title: richTitle }} />
    );
    const bookingSubTitle = intl.formatMessage({ id: isInstant ? 'ListingPage.bookingSubTitleInstant' : 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });


      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const amenityOptions = findOptionsForSelectFilter('features', filterConfig);
    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const category =
      publicData && publicData.category ? (
        <span>
          {categoryLabel(categoryOptions, publicData.category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

    const openEnquiryHostNotConnected = () => {
        this.setState({ enquiryModalOpen: true })
    }


    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              {this.state.pageLoading ?
              <div className={css.pageLoadingWrapper}>
                <div className={css.spinnerWrapper}>
                  <CircularProgress />
                </div>
              </div>
              : null}
              

              <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.contentContainer}>
                <SectionAvatar user={currentAuthor} params={params} />
                <div className={css.mainContent}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    category={category}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                  />
                  <SectionDescriptionMaybe description={description} />
                  <SectionFeaturesMaybe options={amenityOptions} publicData={publicData} />
                  <SectionRulesMaybe publicData={publicData} />
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                  <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} />
                  <SectionHostMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    sendSubscribeError={sendSubscribeError}
                    sendSubscribeInProgress={sendSubscribeInProgress}
                    sendRequestSubscribeError={sendRequestSubscribeError}
                    sendRequestSubscribeInProgress={sendRequestSubscribeInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                    stripeConnected={this.state.stripeConnected}
                    paypalConnected={this.state.paypalConnected}
                  />
                </div>
                {isSubscribed && !isLastSubscriptionEnded ?
                  <center className={css.alreadySubscribedLabel}>
                    <p>
                    Jemand hat diese Garage bereits abonniert
                    </p>
                  </center> :
                  <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  onSubmitSubscribe={this.onSubmitSubscribe}
                  onSubmitSubscribePaypal={this.onSubmitSubscribePaypal}
                  onSubmitRequestSubscribe={this.onSubmitRequestSubscribe}
                  onSendToPaypalSubscribe={this.onSendToPaypalSubscribe}
                  date={this.state.date}
                  setDate={this.setDate}
                  loading={this.state.loading}
                  subscriptionPeriodLimit={this.state.subscriptionPeriodLimit}
                  setSubscriptionPeriodLimit={this.setSubscriptionPeriodLimit}
                  currentListing={currentListing}
                  stripeConnected={this.state.stripeConnected}
                  paypalConnected={this.state.paypalConnected}
                  openEnquiryHostNotConnected={openEnquiryHostNotConnected}
                />
              
                }
              
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  sendSubscribeError: null,
  sendRequestSubscribeError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  sendSubscribeInProgress: bool.isRequired,
  sendSubscribeError: propTypes.error,
  onSubscribe: func.isRequired,
  sendRequestSubscribeInProgress: bool.isRequired,
  sendRequestSubscribeError: propTypes.error,
  onRequestSubscribe: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendSubscribeInProgress,
    sendSubscribeError,
    sendRequestSubscribeInProgress,
    sendRequestSubscribeError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendSubscribeInProgress,
    sendSubscribeError,
    sendRequestSubscribeInProgress,
    sendRequestSubscribeError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onSubscribe: (listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo, authorId) => dispatch(subscribe(listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo, authorId)),
  onSubscribePaypal: (listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo) => dispatch(subscribePaypal(listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo)),
  onRequestSubscribe: (listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo) => dispatch(requestSubscribe(listingId, selectedDate, currentListing, currentUser, subscriptionPeriodLimit, emailInfo)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
